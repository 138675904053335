@font-face {
  font-display: swap;
  font-family: "Futura LT Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/FuturaLTProBook.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Futura LT Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/FuturaLTProBook.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Futura LT Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/FuturaLTProBook.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Futura LT Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/FuturaLTProBook.otf") format("opentype");
}

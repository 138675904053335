@font-face {
  font-display: swap;
  font-family: "Santral Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Santral-Light.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Santral Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Santral-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Santral Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Santral-Medium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Santral Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/Santral-Bold.otf") format("opentype");
}

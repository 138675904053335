@font-face {
  font-display: swap;
  font-family: "Sweet Sans Pro Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/SweetSansPro-Light.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Sweet Sans Pro Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/SweetSansPro-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Sweet Sans Pro Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/SweetSansPro-Medium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Sweet Sans Pro Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/SweetSansPro-Bold.otf") format("opentype");
}

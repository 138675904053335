@font-face {
  font-display: swap;
  font-family: "Versace Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Versace-Regular.ttf") format("ttf"),
    url("../../fonts/Versace-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Versace Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Versace-Regular.ttf") format("ttf"),
    url("../../fonts/Versace-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Versace Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Versace-Regular.ttf") format("ttf"),
    url("../../fonts/Versace-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Versace Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/Versace-Regular.ttf") format("ttf"),
    url("../../fonts/Versace-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Selawik Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Selawik-Light.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Selawik Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Selawik-Regular.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Selawik Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Selawik-Medium.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Selawik Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/Selawik-Bold.ttf") format("ttf");
}

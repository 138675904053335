@font-face {
  font-display: swap;
  font-family: "RadiantURWBol Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/RadiantURW-Bol.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "RadiantURWBol Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/RadiantURW-Bol.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "RadiantURWBol Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/RadiantURW-Bol.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "RadiantURWBol Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/RadiantURW-Bol.otf") format("opentype");
}

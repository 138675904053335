@font-face {
  font-display: swap;
  font-family: "Din-Pro Condensed Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/DINProCondensed-Light-300.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Din-Pro Condensed Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/DINProCondensed-Regular-400.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Din-Pro Condensed Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/DINProCondensed-Medium-500.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Din-Pro Condensed Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/DINProCondensed-Bold-700.otf") format("opentype");
}

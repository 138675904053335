@font-face {
  font-display: swap;
  font-family: "Futura STD Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/FuturaStd-Light.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Futura STD Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/FuturaStd-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Futura STD Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/FuturaStd-Medium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Futura STD Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/FuturaStd-Bold.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Hurme Geometric Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/HurmeGeometric-Thin.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Hurme Geometric Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/HurmeGeometric-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Hurme Geometric Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/HurmeGeometric-SemiBold.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Hurme Geometric Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/HurmeGeometric-Bold.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Fenice STD Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/FeniceStd-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Fenice STD Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/FeniceStd-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Fenice STD Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/FeniceStd-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Fenice STD Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/FeniceStd-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Din-Pro Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/DINPro-Light-300.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Din-Pro Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/DINPro-400.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Din-Pro Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/DINPro-Medium-500.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Din-Pro Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/DINPro-Bold-700.otf") format("opentype");
}

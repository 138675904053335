@font-face {
  font-display: swap;
  font-family: "Ray-Ban Sans Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Ray-BanSans-Light.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Ray-Ban Sans Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Ray-BanSans-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Ray-Ban Sans Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Ray-BanSans-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Ray-Ban Sans Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/Ray-BanSans-Bold.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "72 Condensed Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/72-CondensedBold.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "72 Condensed Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/72-CondensedBold.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "72 Condensed Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/72-CondensedBold.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "72 Condensed Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/72-CondensedBold.ttf") format("ttf");
}

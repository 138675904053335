@font-face {
  font-display: swap;
  font-family: "Aktiv Grotesk Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/AktivGrotesk-Light.ttf") format("ttf"),
    url("../../fonts/AktivGrotesk-Light.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Aktiv Grotesk Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/AktivGrotesk-Regular.ttf") format("ttf"),
    url("../../fonts/AktivGrotesk-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Aktiv Grotesk Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/AktivGrotesk-Medium.ttf") format("ttf"),
    url("../../fonts/AktivGrotesk-Medium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Aktiv Grotesk Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/AktivGrotesk-Bold.ttf") format("ttf"),
    url("../../fonts/AktivGrotesk-Bold.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Ignited Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/EuclidIgnited-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Ignited Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/EuclidIgnited-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Ignited Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/EuclidIgnited-Semibold.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Euclid Ignited Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/EuclidIgnited-Semibold.otf") format("opentype");
}

/* CSS RESET */

/* http://meyerweb.com/eric/tools/css/reset/ 
v2.0 | 20110126
License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 0;
  cursor: pointer;
}

:focus {
  outline: none;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* FONTS */

@font-face {
  font-display: swap;
  font-family: "Gilmer Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Gilmer-Bold"), url("../fonts/Gilmer-Bold.ttf") format("truetype"),
    url("../fonts/Gilmer-Bold.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Gilmer Heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Gilmer-Heavy"), url("../fonts/Gilmer-Heavy.ttf") format("truetype"),
    url("../fonts/Gilmer-Heavy.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Gilmer Light";
  font-style: normal;
  font-weight: normal;
  src: local("Gilmer-Light"), url("../fonts/Gilmer-Light.ttf") format("truetype"),
    url("../fonts/Gilmer-Light.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Gilmer Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Gilmer-Medium"), url("../fonts/Gilmer-Medium.ttf") format("truetype"),
    url("../fonts/Gilmer-Medium.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Gilmer Outline";
  font-style: normal;
  font-weight: normal;
  src: local("Gilmer-Outline"), url("../fonts/Gilmer-Outline.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Gilmer";
  font-style: normal;
  font-weight: normal;
  src: local("Gilmer"), url("../fonts/Gilmer.ttf") format("truetype"),
    url("../fonts/Gilmer.woff") format("woff");
}

/* FONT CMS*/
/* @font-face {
  font-family: 'Space Grotesk';
  src: url('Font/Decima-Mono-Regular/SpaceGrotesk-Regular.eot');
  src: url('Font/Decima-Mono-Regular/SpaceGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
      url('Font/Decima-Mono-Regular/SpaceGrotesk-Regular.woff2') format('woff2'),
      url('Font/Decima-Mono-Regular/SpaceGrotesk-Regular.woff') format('woff'),
      url('Font/Decima-Mono-Regular/SpaceGrotesk-Regular.ttf') format('truetype'),
      url('Font/Decima-Mono-Regular/SpaceGrotesk-Regular.svg#SpaceGrotesk-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Decima Mono Cyr';
  src: url('Font/Decima-Mono-Regular/DecimaMonoCyr.eot');
  src: url('Font/Decima-Mono-Regular/DecimaMonoCyr.eot?#iefix') format('embedded-opentype'),
      url('Font/Decima-Mono-Regular/DecimaMonoCyr.woff2') format('woff2'),
      url('Font/Decima-Mono-Regular/DecimaMonoCyr.woff') format('woff'),
      url('Font/Decima-Mono-Regular/DecimaMonoCyr.ttf') format('truetype'),
      url('Font/Decima-Mono-Regular/DecimaMonoCyr.svg#DecimaMonoCyr') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Space Grotesk';
  src: url('Font/Space-Grotesk-Regular/SpaceGrotesk-Regular.eot');
  src: url('Font/Space-Grotesk-Regular/SpaceGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
      url('Font/Space-Grotesk-Regular/SpaceGrotesk-Regular.woff2') format('woff2'),
      url('Font/Space-Grotesk-Regular/SpaceGrotesk-Regular.woff') format('woff'),
      url('Font/Space-Grotesk-Regular/SpaceGrotesk-Regular.ttf') format('truetype'),
      url('Font/Space-Grotesk-Regular/SpaceGrotesk-Regular.svg#SpaceGrotesk-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vanguard CF';
  src: url('Font/Vanguard-Bold/VanguardCFBold.eot');
  src: url('Font/Vanguard-Bold/VanguardCFBold.eot?#iefix') format('embedded-opentype'),
      url('Font/Vanguard-Bold/VanguardCFBold.woff2') format('woff2'),
      url('Font/Vanguard-Bold/VanguardCFBold.woff') format('woff'),
      url('Font/Vanguard-Bold/VanguardCFBold.ttf') format('truetype'),
      url('Font/Vanguard-Bold/VanguardCFBold.svg#VanguardCFBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vanguard CF';
  src: url('Font/Vanguard-Semibold/VanguardCFDemiBold.eot');
  src: url('Font/Vanguard-Semibold/VanguardCFDemiBold.eot?#iefix') format('embedded-opentype'),
      url('Font/Vanguard-Semibold/VanguardCFDemiBold.woff2') format('woff2'),
      url('Font/Vanguard-Semibold/VanguardCFDemiBold.woff') format('woff'),
      url('Font/Vanguard-Semibold/VanguardCFDemiBold.ttf') format('truetype'),
      url('Font/Vanguard-Semibold/VanguardCFDemiBold.svg#VanguardCFDemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

:root {
  --gray-light-color: #f7f8f9;
  --primary-color: #031434;
}

body {
  font-family: "Gilmer Medium";
  -webkit-font-smoothing: antialiased;
}

/* STICKY FOOTER */

html,
body,
#root {
  height: 100%;
  color: #4b4b4b;
}

#root {
  min-width: 1024px;
  overflow: auto;
  /*ATTENTION IF YOU CHANGE THIS, YOU HAVE TO CHANGE ALL EVENTS CONNECTED TO ROOT DIV*/
}

a {
  color: inherit;
  text-decoration: none;
}

/* SCROLLBAR */

::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
  /*Da impostare per la scrollbar orizzontale. 
Molto piccola, quasi non si riesce a puntare il mouse sopra!*/
}

::-webkit-scrollbar-track {
  background-color: var(--gray-light-color);
  border-radius: 0.1rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 0.1rem;
}

/* no arrows in number input */

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0mm;
    margin-bottom: 1cm;
  }
  html {
    font-size: 12px;
    height: max-content !important;
  }
  #header,
  #sticky-footer,
  #tealiumLog_loggerContainer {
    display: none !important;
  }
  .print-ready {
    padding: 0 !important;
    margin: 0 !important;
    max-width: none !important;
    min-width: auto !important;
  }
  .print-hide {
    display: none !important;
  }
  .print-section {
    display: block !important;
  }
  .col-9 {
    width: 100% !important;
  }
  #customer-reference > button {
    width: unset !important;
    border: unset !important;
  }
  .warranty-details .warranty-grid {
    row-gap: 1rem;
  }
  .warranty-details .warranty-grid-container {
    padding: 1rem;
  }
}

/*CMS classes for richtext*/

.align--left {
  text-align: left;
}

.align--right {
  text-align: right;
}

.align--center {
  text-align: center;
}

.align--justify {
  text-align: justify;
}

.QSIWebResponsive-creative-container-fade {
  z-index: 400 !important;
}

.QSIWebResponsiveShadowBox {
  z-index: 300 !important;
}

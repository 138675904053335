@font-face {
  font-display: swap;
  font-family: "Caboto Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/Caboto-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Caboto Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Caboto-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Caboto Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Caboto-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Caboto Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/Caboto-Bold.otf") format("opentype");
}

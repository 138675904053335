@font-face {
  font-display: swap;
  font-family: "Helvetica Neue Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/HelveticaNeueLTPro-Regular.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Helvetica Neue Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/HelveticaNeueLTPro-Regular.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Helvetica Neue Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/HelveticaNeueLTPro-Semibold.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Helvetica Neue Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/HelveticaNeueLTPro-Bold.ttf") format("ttf");
}

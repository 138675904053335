@font-face {
  font-display: swap;
  font-family: "Burberry Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/BurberryMedium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Burberry Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/BurberryMedium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Burberry Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/BurberryMedium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Burberry Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/BurberryMedium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Chanel Corpo Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/ChanelCorpo-ExtraLight.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Chanel Corpo Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/ChanelCorpo-Light.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Chanel Corpo Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/ChanelCorpo-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Chanel Corpo Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/ChanelCorpo-SemiBold.otf") format("opentype");
}

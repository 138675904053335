@font-face {
  font-display: swap;
  font-family: "Kors Sans Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/KorsSans-Medium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Kors Sans Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/KorsSans-Medium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Kors Sans Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/KorsSans-Medium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Kors Sans Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/KorsSans-Medium.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "ITC Novarese for Swarovski Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/ITCNovareseforSwarovskiBk.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "ITC Novarese for Swarovski Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/ITCNovareseforSwarovskiBk.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "ITC Novarese for Swarovski Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/ITCNovareseforSwarovskiMd.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "ITC Novarese for Swarovski Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/ITCNovareseforSwarovskiMd.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Bembo MT Pro Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/BemboMTPro-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Bembo MT Pro Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/BemboMTPro-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Bembo MT Pro Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/BemboMTPro-Regular.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Bembo MT Pro Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/BemboMTPro-Regular.otf") format("opentype");
}

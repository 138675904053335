@font-face {
  font-display: swap;
  font-family: "Garamond Narrow Plain Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/GaramondNarrowPlain.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Garamond Narrow Plain Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/GaramondNarrowPlain.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Garamond Narrow Plain Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/GaramondNarrowPlain.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Garamond Narrow Plain Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/GaramondNarrowPlain.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Calibri Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/CalibriLight.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Calibri Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/CalibriRegular.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Calibri Medium";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/CalibriRegular.ttf") format("ttf");
}

@font-face {
  font-display: swap;
  font-family: "Calibri Bold";
  font-style: normal;
  font-weight: 800;
  src: url("../../fonts/CalibriBold.ttf") format("ttf");
}
